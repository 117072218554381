import React from "react";
import calendarIco from './calend-icon.png';
import InlineDatepicker from "src/components/LegacyDatepicker/InlineDatepicker";

export default ({ eventDate, minEventDate, maxEventDate, onChange }) => (
  <div className='calendar-block'>
    <h3>
      <img src={calendarIco}/>
      Calendar
    </h3>

    <InlineDatepicker
      value={eventDate}
      minDate={minEventDate}
      maxDate={maxEventDate}
      onChange={onChange}
      view='block'
    />
  </div>
);