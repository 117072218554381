import React, { useCallback, useEffect, useState } from 'react';
import Modal from "../Modal/Index";
import { useModal } from '../useModal';
import axios from "axios";
import { withRouter } from 'react-router-dom';
import Header from './Header';
import Table from './Table';
import Pagination from "../../Reports/Pagination";

function Index({ location, history }) {
  const [reportsLoaded, setReportsLoaded] = useState(false);
  const [reportSchedules, setReportSchedules] = useState([]);
  const [currentUserIsRo, setCurrentUserIsRo] = useState([]);
  const [reportSchedulesCount, setReportSchedulesCount] = useState(0);
  const [pagination, setPagination] = useState(null);
  const [page, setPage] = useState(1);
  const [item, setItem] = useState(null);

  const updateReportSchedules = useCallback((page)=> {
    const params = { page: page }

    axios
      .get('/admin/reports/schedules.json', { params: params })
      .then((response) => {
        const { reportSchedules, reportSchedulesCount, pagination, item } = response.data;

        setReportSchedules(reportSchedules);
        setPagination(pagination);
        setItem(item);
        setReportsLoaded(true);
      });
  }, []);

  const [modal, showModal] = useModal(Modal, updateReportSchedules);

  const handleChangePage = (page) => { setPage(page) };
  const handleDelete = (scheduleId) => {
    if (confirm('Are you sure?')) {
      axios
        .delete(`/admin/reports/schedules/${scheduleId}.json`)
        .then((response) => updateReportSchedules());
    }
  };

  useEffect(() => {
    axios
      .get('/admin/reports/schedules.json')
      .then((response) => {
        const { currentUserIsRo } = response.data;
        setCurrentUserIsRo(currentUserIsRo);
      });

    updateReportSchedules();
  }, [])

  useEffect(() => {
    updateReportSchedules(page);
  }, [page]);


  return (
    <>
      {modal}

      <Header reportsLoaded={reportsLoaded}
              reportSchedulesCount={reportSchedulesCount}
              currentUserIsRo={currentUserIsRo}
              showModalCreate={showModal}
              item={item}/>

      <Table reportSchedules={reportSchedules}
             showModalEdit={showModal}
             handleDelete={handleDelete}/>

      {pagination && pagination.pages > 1 &&
      <Pagination
        totalPages={pagination.pages}
        currentPage={pagination.page}
        onChange={handleChangePage}
      />
      }
    </>
  )
}

export default withRouter(Index);
