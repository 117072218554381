import {OverlayTrigger, Popover} from "react-bootstrap";
import React, {useMemo} from "react";
import classnames from "classnames";
import {FlashMessage} from "src/components/MainSearch/FlashMessage";
import { useWindowPosition } from "../hooks/useWindowPosition";
import ExportButtons from '../../Reports/Export/Buttons';

const AddButton = ({ disabled, onClick }) => {
  const buttonClassNames = useMemo(() =>
    classnames(
      'btn add-schedule-button',
      { 'btn-primary': !disabled },
      { 'disabled': disabled }
    ), [disabled])

  return (
    <button className={buttonClassNames} onClick={onClick}>
      Add report schedule
    </button>
  )
}

const ExportButton = ({ showButton, onClick }) => {
  return (
    <button className='btn btn-primary mr-2' onClick={onClick}>
      Export to Excel
    </button>
  )
}

const showNotice = () => {
  FlashMessage(
    "You have the reached the maximum number of scheduled reports. " +
    "You will need to remove an existing one before being able to " +
    "create a new one."
  )
}

export default ({ currentUserIsRo, reportsLoaded, reportSchedulesCount, showModalCreate, item }) => {
  const disabled = !reportsLoaded || reportSchedulesCount >= 10;

  const position = useWindowPosition();

  const reportScheduleInfoPopover = (
    <Popover id="popover-positioned-scrolling-left" title="">
      <p>
        Any reports scheduled will be sent via email based on the filters you have
        selected.
      </p>
      <p>
        As an example, you can select for the Activity Report to be
        emailed to you weekly on a Sunday for the upcoming week
        {currentUserIsRo && <span>.</span>}
        {!currentUserIsRo &&
          <span>
            , or the Income per Facility Report to be sent to you monthly for the Current Academic Year.
          </span>
        }
      </p>
    </Popover>
  )

  return (
    <div className=''>
      <h1>
        Scheduled Reports
        <OverlayTrigger container={this} placement={position} overlay={reportScheduleInfoPopover}>
          <div className="info-icon" data-original-title="" title=""/>
        </OverlayTrigger>

        <div className="btn-group pull-right">
          <AddButton disabled={disabled} onClick={(e) => disabled ? showNotice(e) : showModalCreate({})}/>
        </div>

        {item && item.showExportButton &&
        <div className="btn-group pull-right mr-2">
          <ExportButtons filter={{}} item={item} exportFormats={item.exportFormats}/>
        </div>
        }
      </h1>
    </div>
  )
}