import React, { useState, useMemo } from 'react';
import moment from 'moment';
import Calendar from './Calendar';
import classNames from 'classnames';

const momentDate = (date) => moment(date).local().format('ddd, D MMM YYYY');

export default ({ rangeLimit, timePeriod, onDateChange }) => {
  const [selectedRange, setSelectedRange] = useState({
    from: timePeriod.startDate,
    to: timePeriod.endDate
  });

  const [startDateMonth, setStartDateMonth] = useState(new Date());
  const [endDateMonth, setEndDateMonth]  = useState(new Date());

  const setStartDate = (startDate) => {
    const range = { ...selectedRange, from: startDate };
    if (range.to < range.from) range.to = null;

    if (range.from && range.to) {
      const momentFrom = moment(range.from);
      const momentTo = moment(range.to);

      if (momentTo.diff(momentFrom, 'days') > rangeLimit) {
        range.to = momentFrom.add(rangeLimit, 'day').toDate();
      }
    }

    if (range.to === null) {
      const isAfterMonth = moment(startDateMonth).isAfter(moment(endDateMonth), 'month');
      if (isAfterMonth) setEndDateMonth(range.from)
    } else {
      const isSameMonth = moment(range.to).isSame(moment(endDateMonth), 'month');
      if (!isSameMonth) setEndDateMonth(range.to);
    }

    setSelectedRange(range);
  };

  const setEndDate = (endDate) => {
    const range = {...selectedRange, to: endDate}
    if (range.to < range.from) range.from = null;

    if (range.from && range.to) {
      let momentFrom = moment(range.from);
      let momentTo = moment(range.to);

      if (momentTo.diff(momentFrom, 'days') > rangeLimit) {
        range.from = momentTo.subtract(rangeLimit, 'day').toDate();
      }
    }

    if (range.from === null) {
      const isBeforeMonth = moment(endDateMonth).isBefore(moment(startDateMonth), 'month');
      if (isBeforeMonth) setStartDateMonth(range.to);
    } else {
      const isSameMonth = moment(range.from).isSame(moment(startDateMonth), 'month');
      if (!isSameMonth) setStartDateMonth(range.from);
    }

    setSelectedRange(range);
  };

  const selectedDates = useMemo(() => {
    const { from, to } = selectedRange;
    const values = [from, to];
    const nonNullValues = values.filter(value => value !== null);

    if (nonNullValues.length === 0) {
      return null;
    } else if (nonNullValues.length === 1 ||  moment(from).isSame(moment(to), 'day')) {
      return { from: nonNullValues[0], to: nonNullValues[0] };
    } else {
      return selectedRange;
    }
  }, [selectedRange.from, selectedRange.to]);

  const dateStr = useMemo(() => {
    if (selectedDates === null) {
      return null;
    } else if (selectedDates.from === selectedDates.to) {
      return `${momentDate(selectedDates.from)}`
    } else {
      return `${momentDate(selectedDates.from)} - ${momentDate(selectedDates.to)}`
    }
  }, [selectedDates]);

  const enabledButton = useMemo(() => dateStr !== null, [dateStr]);

  const currentDate = moment();
  const fromDate = useMemo(() => currentDate.clone().subtract(2, 'years'), [currentDate]);
  const toDate = useMemo(() =>  currentDate.clone().add(6, 'years'), [currentDate]);

  return (
    <>
      <div className='calendar-wrapper'>
        <div className='calendar-left-part'>
          <Calendar
            dateMonth={startDateMonth}
            setDateMonth={setStartDateMonth}
            onDayClick={(d) => setStartDate(d)}
            selectedRange={selectedRange}
            minDate={fromDate}
            maxDate={toDate}
          />
        </div>

        <div className='calendar-center-part'>TO</div>

        <div className='calendar-right-part'>
          <Calendar
            dateMonth={endDateMonth}
            setDateMonth={setEndDateMonth}
            onDayClick={(d) => setEndDate(d)}
            selectedRange={selectedRange}
            minDate={fromDate}
            maxDate={toDate}
          />
        </div>
      </div>

      <div className='text-center text-sm-center p-2'>
        {dateStr}
      </div>

      <div style={{margin: '0px 10px 10px 10px'}}>
        <button className={classNames(['btn btn-primary btn-block', {'btn-grey': !enabledButton}])}
                disabled={!enabledButton}
                onClick={() => { console.log(selectedDates); onDateChange(selectedDates)} }>
        Apply
        </button>
      </div>
    </>
  )
};